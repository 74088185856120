import { INDEXER_OPTIONS } from '@common/constants/INDEXER_OPTIONS';
import { PRODUCT_OPTIONS } from '@common/constants/PRODUCT_OPTIONS';
import { Button } from '@invest-ai/animatrix';

import {
  FormDatePicker,
  FormEasySelect,
  FormPercentageInput,
  FormTextField,
} from '@invest-ai/animatrix';
import { Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FixedIncomeProductKind,
  Indexers,
} from '../../../../../../../__generated__/types';
import { TARGET_PUBLIC_OPTIONS } from '../../../../../../common/constants/TARGET_PUBLIC_OPTIONS';
import { floatTransformer } from '../../../../../../common/logic/floatTransformer';
import { useDealDashboard } from '../../../../../../deals/hooks/useDealDashboard';
import { useSelectedOffer } from '../../../../../context/SelectedOfferContext';
import { useDeleteOffer } from '../../../../../hooks/useDeleteOffer';
import { useUpdateFixedIncomeOffer } from '../../../../../hooks/useUpdateOffer';
import { CustomIndexer, IFormData } from '../../../../../interfaces/IFormData';
import { fixValues } from '../../../../../logic/fixValuesFromOfferForm';
import { FREE_OF_IR_OPTIONS } from './FREE_OF_IR_OPTIONS';

interface IEditOfferForm {
  offerId: string;
  values: IFormData;
  onSuccess: () => void;
  onCancel: () => void;
  onDelete: () => void;
}

export const EditOfferForm: React.FC<IEditOfferForm> = ({
  offerId,
  values,
  onSuccess,
  onCancel,
  onDelete,
}) => {
  const { deleteFixedIncomeOffer, loading: loadingDeleteOffer } =
    useDeleteOffer();
  const { updateFixedIncomeOffer, loading: loadingEditOffer } =
    useUpdateFixedIncomeOffer();
  const { selectedOfferId } = useSelectedOffer();
  const params = useParams<{ positionId: string }>();
  const positionId = params.positionId as string;
  const { refetch } = useDealDashboard({
    positionId,
    offerId,
  });
  const methods = useForm<IFormData>({
    mode: 'onChange',
    defaultValues: values,
  });
  const onSubmit = async (data: IFormData) => {
    const fixedValues = fixValues(data);
    try {
      await updateFixedIncomeOffer(offerId, fixedValues);
      // await refetch();
      toast.success('Oferta editada com sucesso');
      onSuccess();
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };

  async function deleteOffer() {
    try {
      if (!offerId) {
        return;
      }
      const confirmed = confirm('Deseja excluir a oferta?');
      if (!confirmed) {
        return;
      }
      await deleteFixedIncomeOffer(offerId);
      toast.success('Oferta deletada com sucesso');
      onDelete();
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  }

  const indexer = methods.watch('indexer');
  const productKind = methods.watch('productKind');
  const shouldDisplayIndexerPercentRate = indexer === Indexers.CDI;
  const shouldDisplayFreeOfIR =
    productKind === FixedIncomeProductKind.DEBENTURE;

  const shouldDisplayYieldRate = [
    Indexers.IPCA,
    Indexers.PREFIXADO,
    CustomIndexer.CDI_PLUS,
  ].includes(indexer);

  const shouldDisplayDuration = [
    FixedIncomeProductKind.CRI,
    FixedIncomeProductKind.CRA,
    FixedIncomeProductKind.DEBENTURE,
  ].includes(productKind);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <FormTextField
              name='issuerId'
              control={methods.control}
              label='Banco'
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              name='nickname'
              control={methods.control}
              label='Nome do ativo'
              rules={{
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormEasySelect
              name='productKind'
              control={methods.control}
              label='Produto'
              fullWidth
              options={PRODUCT_OPTIONS}
              rules={{
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormEasySelect
              name='indexer'
              fullWidth
              control={methods.control}
              label='Indexador'
              options={INDEXER_OPTIONS}
              rules={{
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            {shouldDisplayYieldRate && (
              <FormPercentageInput
                control={methods.control}
                name='yieldRate'
                label='Taxa de aplicação (%)'
                precision={2}
                rules={{
                  required: true,
                }}
              />
            )}

            {shouldDisplayIndexerPercentRate && (
              <FormPercentageInput
                control={methods.control}
                name='indexerPercentRate'
                label='Taxa de aplicação (%)'
                precision={0}
                rules={{
                  required: true,
                }}
              />
            )}
          </Grid>

          <Grid item xs={6}>
            <FormPercentageInput
              control={methods.control}
              name='roa'
              label='ROA (%)'
              precision={2}
              rules={{
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormDatePicker
              name='maturityDate'
              control={methods.control}
              label='Data de vencimento'
              rules={{
                required: true,
              }}
              fullWidth
            />
          </Grid>

          {shouldDisplayFreeOfIR && (
            <Grid item xs={6}>
              <FormEasySelect
                fullWidth
                control={methods.control}
                name='freeOfIR'
                label='Ativo isento'
                options={FREE_OF_IR_OPTIONS}
                rules={{
                  required: true,
                }}
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <FormEasySelect
              control={methods.control}
              fullWidth
              name='target'
              label='Público Alvo'
              options={TARGET_PUBLIC_OPTIONS}
              rules={{
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormTextField
              name='rating'
              control={methods.control}
              label='Rating*'
            />
          </Grid>

          <Grid item xs={6}>
            {shouldDisplayDuration && (
              <FormTextField
                name='duration'
                control={methods.control}
                label='Duration (em anos)'
                type='number'
                inputProps={{
                  step: '.01',
                }}
                transform={floatTransformer}
                rules={{
                  required: true,
                }}
              />
            )}
          </Grid>

          <Grid item xs={12} display='flex' justifyContent='flex-end'>
            <Button variant='text' sx={{ mr: 2 }} onClick={onCancel}>
              Cancelar
            </Button>

            <Button
              loading={loadingDeleteOffer}
              variant='soft'
              sx={{ mr: 2 }}
              onClick={deleteOffer}
              color='error'
            >
              Excluir oferta
            </Button>

            <Button
              variant='contained'
              sx={{ minWidth: 200 }}
              type='submit'
              loading={loadingEditOffer}
              disabled={!methods.formState.isValid}
              color='primary'
            >
              Salvar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Typography fontSize={12} color='#637381'>
              *: campos opcionais
            </Typography>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
