import {
  FixedIncomeProductKind,
  Indexers,
} from '../../../../../../__generated__/types';
import { useFixedIncomeOfferById } from '../../../../hooks/useFixedIncomeOfferById';
import { IFormData } from '../../../../interfaces/IFormData';

export const getFormOfferFromOffer = (
  offer: ReturnType<typeof useFixedIncomeOfferById>['fixedIncomeOffer'],
): IFormData => ({
  issuerId: offer?.issuerId ?? '',
  nickname: offer?.nickname || '',
  productKind: offer?.productKind || FixedIncomeProductKind.CRI,
  indexer: offer?.yieldDescription.indexer || Indexers.PREFIXADO,
  yieldRate: offer?.yieldDescription.yieldRate || 0,
  indexerPercentRate: offer?.yieldDescription.indexerPercentRate || 0,
  roa: offer?.roa || 0,
  maturityDate: offer?.maturityDate || new Date(),
  freeOfIR: offer?.freeOfIR ? 1 : 0 || 0,
  target: offer?.target,
  rating: offer?.rating || '',
  duration: offer?.duration || 0,
});
