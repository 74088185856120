import {
  FixedIncomeOfferTarget,
  FixedIncomeProductKind,
  Indexers,
} from '../../../__generated__/types';

export enum CustomIndexer {
  CDI_PLUS = 'CDI_PLUS',
}

export interface IFormData {
  nickname: string;
  issuerId: string;
  productKind: FixedIncomeProductKind;
  indexer: Indexers;
  yieldRate: number;
  roa: number;
  maturityDate: Date;
  freeOfIR: 1 | 0;
  target: FixedIncomeOfferTarget | undefined;
  rating: string;
  indexerPercentRate: number;
  duration: number;
}
